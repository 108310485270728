import React, { Component } from "react";

export class Pill extends Component {
    render() {
        return (
            <span className={"badge rounded-pill pill " + this.props.type}>
                { this.props.data || this.props.children }
            </span>
        )
    }
};