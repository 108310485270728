import axios from "axios";
import {
  API_ADMIN_BASEURL,
  API_BASEURL,
} from "../../components/config/constants";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const ApiUtils = {
  // Login
  login: function (params) {
    return axios
      .post(API_ADMIN_BASEURL + "/auth/login", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //User Session Details
  userSessionDetails: function (params) {
    return axios
      .post(API_BASEURL + "/user/session-details", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Delete User Session Detail
  deleteSingleSession: function (params) {
    return axios
      .post(API_BASEURL + "/user/delete-login-session", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Otp Veirfy By Email
  otpVerifyByEmail: function (params) {
    return axios
      .post(API_BASEURL + "/user/otp/verify-by-email", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //Otp Veirfy By Id
  otpVerifyById: function (params) {
    return axios
      .post(API_BASEURL + "/user/otp/verify-by-id", params, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get User Details By Id
  userDetails: function (params) {
    return axios
      .post(API_BASEURL + `/user/details`, params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Forgot Password
  forgotPassword: function (params) {
    return axios
      .post(API_ADMIN_BASEURL + "/auth/send-email", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Reset password
  resetPassword: function (token, params) {
    return axios
      .post(API_ADMIN_BASEURL + "/auth/recovery-password/" + token, params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Change password
  changePassword: function (params) {
    return axios
      .post(API_BASEURL + "/user/change-password", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Change Fee
  changeFee: function (feeId, params) {
    return axios
      .patch(API_ADMIN_BASEURL + "/referrals-fees/" + feeId, params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Create Fee
  createFee: function (params) {
    return axios
      .post(API_ADMIN_BASEURL + "/referrals-fees", params, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Get User
  getUser: function (userType, limit, search) {
    return axios
      .get(
        API_ADMIN_BASEURL +
          `/user?userType=${userType}&limit=${limit}&search=${search}`,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  //get reported content details
  getReportedContentDetails: function (id) {
    return axios
      .get(API_ADMIN_BASEURL + `/reports/${id}`, {
        headers: headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },

  // Logout
  logout: function (params) {
    return axios
      .delete(API_ADMIN_BASEURL + "/auth/logout", params, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  },
};
export default ApiUtils;
