export const Types = {
    LOGIN: 'LOGIN',
    IP_INFO_DETAILS: 'IP_INFO_DETAILS',
    REMOVE_IP_INFO_DETAILS: 'REMOVE_IP_INFO_DETAILS',
    EDIT: 'EDIT',
    EDIT_TOKEN: 'EDIT_TOKEN',
    UPDATE_SUBSCRIBED_USER_COUNT: 'UPDATE_SUBSCRIBED_USER_COUNT',
    ADD_STRIPE_CUSTOMER_ID: 'ADD_STRIPE_CUSTOMER_ID',
    LOGOUT: 'LOGOUT',
    ADD_NOTIFICATION_COUNT: 'ADD_NOTIFICATION_COUNT',
    UPDATE_NOTIFICATION_COUNT: 'UPDATE_NOTIFICATION_COUNT',
    CALL_NOTIFICATION_API: 'CALL_NOTIFICATION_API',
    SOCKET_DETAILS: 'SOCKET_DETAILS',
    REMOVE_SOCKET_DETAILS: 'REMOVE_SOCKET_DETAILS'
}