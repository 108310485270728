import BaseService from "./BaseService";
import axios from "axios";
import { API_ADMIN_BASEURL, headers } from "../config/constants";

export default class UserService extends BaseService {
  NAME = "user";

  constructor() {
    super();
    this.setPath(this.NAME);
  }

  totalUsers() {
    return axios
      .get(this.mapUrl("total-users"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  bestCreatorsByUploadedClip() {
    return axios
      .get(this.mapUrl("best-creators-by-uploaded-clips"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  bestCreatorsByUploadedMessage() {
    return axios
      .get(this.mapUrl("best-creators-by-chats"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  getFilters() {
    return axios
      .get(API_ADMIN_BASEURL + "/my-filters", { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  setFilters(params) {
    return axios
      .post(API_ADMIN_BASEURL + "/my-filters", params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  cancelSubscription(id) {
    return axios
      .patch(
        API_ADMIN_BASEURL + `/user-subscriptions/${id}/stop-subscription`,
        {
          headers: headers,
        }
      )
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  disableUser(userId) {
    return axios
      .patch(API_ADMIN_BASEURL + "/user/" + userId, {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  totalCreators() {
    return axios
      .get(this.mapUrl("total-creators"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  amountSpent(id, startDate) {
    return axios
      .get(this.mapUrl(id + "/amount-spent?startDate=" + startDate), {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  reports(id) {
    return axios
      .get(this.mapUrl(id + "/reports"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  subscriptions(id, filters) {
    return axios
      .get(this.mapUrl(id + "/subscriptions") + this.composeFilters(filters), {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  contentUnlocked(id, startDate) {
    return axios
      .get(this.mapUrl(id + "/content-unlocked?startDate=" + startDate), {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  bestCreatorsByReactions() {
    return axios
      .get(this.mapUrl("best-creators-by-reactions"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  bestCreatorsByPosts() {
    return axios
      .get(this.mapUrl("best-creators-by-posts"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  bestCreatorsByChats() {
    return axios
      .get(this.mapUrl("best-creators-by-chats"), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }
}
