import BaseService from "./BaseService";

export default class NotificationService extends BaseService {
    NAME = "notifications";

    constructor() {
        super();
        this.setPath(this.NAME);
    }
}
