import React, { Component } from 'react'

class PublicLayout extends Component {

    render() {
        return (
            <div className="main-wrap" onContextMenu={(e)=> e.preventDefault()}>
                <div className="row">
                    <div className="col-xl-12 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-center">
                                <a href="/"><i className="fa-regular fa-circle text-light-red display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-500 text-black font-xxl logo-text mb-4">RedCircle Public</span> </a>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PublicLayout