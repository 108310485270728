import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import LazyLoader from "@loadable/component";
import { connect } from "react-redux";

// Layouts
import GuestLayout from "../../layout/GuestLayout.jsx";
import PublicLayout from "../../layout/PublicLayout.jsx";
import ProtectedLayout from "../../layout/ProtectedLayout";
import Loader from "../Loader.jsx";
import ErrorPageLayout from "../../layout/ErrorPageLayout";
import SupportDetail from "../../pages/modules/Support/SupportDetail.js";

// Pages
const Login = LazyLoader(() => import("../../pages/modules/Auth/Login"), {
  fallback: <Loader />,
});

const ForgotPassword = LazyLoader(
  () => import("../../pages/modules/Auth/ForgotPassword"),
  {
    fallback: <Loader />,
  }
);
const ResetPassword = LazyLoader(
  () => import("../../pages/modules/Auth/ResetPassword"),
  {
    fallback: <Loader />,
  }
);
const Notfound = LazyLoader(() => import("../../pages/modules/Auth/Notfound"), {
  fallback: <Loader />,
});
const Home = LazyLoader(() => import("../../pages/modules/Home/Home"), {
  fallback: <Loader />,
});
const Kyc = LazyLoader(() => import("../../pages/modules/Kyc/Kyc"), {
  fallback: <Loader />,
});
const Profile = LazyLoader(
  () => import("../../pages/modules/Profile/Profile"),
  {
    fallback: <Loader />,
  }
);
const Log = LazyLoader(() => import("../../pages/modules/Log/Log"), {
  fallback: <Loader />,
});
const Payment = LazyLoader(
  () => import("../../pages/modules/Payment/Payment"),
  {
    fallback: <Loader />,
  }
);
const Referral = LazyLoader(
  () => import("../../pages/modules/Referral/Referral"),
  {
    fallback: <Loader />,
  }
);
const ReferralUser = LazyLoader(
  () => import("../../pages/modules/Referral/ReferralUser"),
  {
    fallback: <Loader />,
  }
);
const Review = LazyLoader(() => import("../../pages/modules/Review/Review"), {
  fallback: <Loader />,
});
const Support = LazyLoader(
  () => import("../../pages/modules/Support/Support"),
  {
    fallback: <Loader />,
  }
);
const User = LazyLoader(() => import("../../pages/modules/User/User"), {
  fallback: <Loader />,
});

const Admin = LazyLoader(() => import("../../pages/modules/Admin/Admin"), {
  fallback: <Loader />,
});

const Vat = LazyLoader(() => import("../../pages/modules/Admin/Vat"), {
  fallback: <Loader />,
});

const Verification = LazyLoader(
  () => import("../../pages/modules/Verification/Verification"),
  {
    fallback: <Loader />,
  }
);
const Withdrawal = LazyLoader(
  () => import("../../pages/modules/Withdrawal/Withdrawal"),
  {
    fallback: <Loader />,
  }
);

class Main extends Component {
  render() {
    const GuestRoute = ({ component: Component, ...rest }) => {
      return (
        <GuestLayout>
          <Route
            {...rest}
            render={(props) =>
              this.props.loggedIn ? (
                <Redirect to="/home" />
              ) : (
                <Component {...props} />
              )
            }
          />
        </GuestLayout>
      );
    };

    const PublicRoute = ({ component: Component, ...rest }) => {
      return (
        <PublicLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </PublicLayout>
      );
    };

    const ProtectedRoute = ({ component: Component, ...rest }) => {
      return (
        <ProtectedLayout>
          <Route
            {...rest}
            render={(props) =>
              this.props.loggedIn ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        </ProtectedLayout>
      );
    };

    const ErrorPageRoute = ({ component: Component, ...rest }) => {
      return (
        <ErrorPageLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </ErrorPageLayout>
      );
    };

    return (
      <div>
        <Switch>
          <PublicRoute exact={true} path="/not-found" component={Notfound} />

          <GuestRoute exact={true} path="/" component={Login} />
          <GuestRoute
            exact={true}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute
            exact={true}
            path="/reset-password/:token"
            component={ResetPassword}
          />

          <ProtectedRoute exact={true} path="/home" component={Home} />
          <ProtectedRoute exact={true} path="/kycs" component={Kyc} />
          <ProtectedRoute exact={true} path="/profile" component={Profile} />
          <ProtectedRoute exact={true} path="/logs" component={Log} />
          <ProtectedRoute exact={true} path="/payments" component={Payment} />
          <ProtectedRoute exact={true} path="/referrals" component={Referral} />
          <ProtectedRoute
            exact={true}
            path="/referral_users"
            component={ReferralUser}
          />
          <ProtectedRoute exact={true} path="/reviews" component={Review} />
          <ProtectedRoute exact={true} path="/supports" component={Support} />
          <ProtectedRoute
            exact={true}
            path="/supports_details"
            component={SupportDetail}
          />
          <ProtectedRoute exact={true} path="/users" component={User} />
          <ProtectedRoute exact={true} path="/admins" component={Admin} />
          <ProtectedRoute exact={true} path="/vat" component={Vat} />
          <ProtectedRoute
            exact={true}
            path="/verifications"
            component={Verification}
          />
          <ProtectedRoute
            exact={true}
            path="/withdrawals"
            component={Withdrawal}
          />

          <ErrorPageRoute component={Notfound} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loggedIn: state.authentication.loggedIn };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
