import axios from 'axios';
import BaseService from './BaseService';
import { headers } from '../config/constants';

export default class PictureService extends BaseService {
  NAME = 'attachments';

  constructor() {
    super();
    this.setPath(this.NAME);
  }

  uploadImage(params) {
    return axios
      .post(this.mapUrl(''), params, {
        headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }
}
