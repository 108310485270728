import React, { Component } from "react";
import { AWS_URL } from "../config/constants";

class AccountVerifyTick extends Component {
  render() {
    return (
      <img
        className="cancel-img-fit"
        height={this.props.height}
        width={this.props.width}
        style={this.props.style ? this.props.style : {}}
        src={`${AWS_URL}logo/verify.svg`}
        alt=""
      />
    );
  }
}

export default AccountVerifyTick;
