import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Appfooter extends Component {
  render() {
    return (
      <div className="app-footer border-0 shadow-lg bg-light-black-gradient">
        {/* <Link to="/home" className="nav-content-bttn nav-center">
          <i className="feather-home"></i>
        </Link> */}
        <Link className="nav-content-bttn mt-3">
          {this?.props?.loggedUserInfo?.userData?.profilePicture ? (
            <figure
              className="avatar z-index-1 user-profile-figure"
              style={{
                background:
                  "url('https://placehold.co/400') 0% 0% / cover no-repeat",
              }}
            ></figure>
          ) : (
            <figure
              className="avatar z-index-1 user-profile-figure"
              style={{
                background:
                  "url('https://placehold.co/400') 0% 0% / cover no-repeat",
              }}
            ></figure>
          )}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUserInfo: state.authentication,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Appfooter);
