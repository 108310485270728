import axios from "axios";
import BaseService from "./BaseService";
import { headers } from "../config/constants";

export default class TicketService extends BaseService {
    NAME = "ticket-messages";

    constructor() {
        super();
        this.setPath(this.NAME);
    }

    sendMessage(ticket, message, attachmentId) {
        return axios
            .post(this.getBaseURL("/"), {ticket, message, attachmentId}, { headers: headers })
            .then((response) => response)
            .catch((error) => {
                throw error.response.data;
            });
    }

}
