import axios from "axios";
import { API_ADMIN_BASEURL, headers } from "../config/constants";

export default class BaseService {
  BASE_PATH = "/";

  setPath(path) {
    this.BASE_PATH = "/" + path;
  }

  getBaseURL() {
    return API_ADMIN_BASEURL + this.BASE_PATH;
  }

  composeFilters(filters) {
    if (filters != null) {
      let query = "?";

      for (const [key, value] of Object.entries(filters)) {
        if (value != null) query += key + "=" + value + "&";
      }

      return query.substring(0, query.length - 1);
    }

    return "";
  }

  mapUrl(path) {
    return API_ADMIN_BASEURL + this.BASE_PATH + "/" + path;
  }

  all(filters) {
    return axios
      .get(this.getBaseURL() + this.composeFilters(filters), {
        headers: headers,
      })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  get(id) {
    return axios
      .get(this.mapUrl(id), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  post(id, params) {
    return axios
      .post(this.mapUrl(id), params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  patch(id, params) {
    return axios
      .patch(this.mapUrl(id), params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  put(id, params) {
    return axios
      .put(this.mapUrl(id), params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  create(params) {
    return axios
      .post(this.getBaseURL(), params, { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }

  delete(id) {
    return axios
      .delete(this.mapUrl(id), { headers: headers })
      .then((response) => response)
      .catch((error) => {
        throw error.response.data;
      });
  }
}
