import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
class ProtectedLayout extends Component {
  render() {
    return (
      <div className="" onContextMenu={(e) => e.preventDefault()}>
        <Header />
        {this.props.children}
        {/* <Appfooter /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedUserInfo: state.authentication,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedLayout);
