export const USER_TYPES = {
  CREATOR: "Creator",
  USER: "User",
};

export const POST_TYPES = {
  NORMAL: "Normal",
  SCHEDULED: "Scheduled",
};

export const POST_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
};

export const FILE_PREFIX_CODE = "255";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export const AWS_URL = process.env.REACT_APP_AWS_S3_URL;
export const API_BASEURL = process.env.REACT_APP_API_USER_BASEURL;
export const API_ADMIN_BASEURL = process.env.REACT_APP_API_ADMIN_BASEURL;
export const SOCKET_BASEURL = process.env.REACT_APP_SOCKET_URL;
export const API_SLUG = {
  HOME: "HOME",
  PROFILE: "PROFILE",
};

export const POST_SECTION_SLUG = {
  COMMENT: "COMMENT",
  REACTION: "REACTION",
};

export const POST_SECTION_VALUES = {
  COMMENT: {
    single: "comment",
    multiple: "comments",
  },
  REACTION: {
    single: "reaction",
    multiple: "reactions",
  },
};

export const REDUX_LOCAL_STORE_KEY = "redcircle";

export const MENU_SLUGS = Object.freeze({
  BOOKMARKS: "BOOKMARKS",
  COLLECTIONS: "COLLECTIONS",
});

export const DATE_FORMAT = {
  LONG: "dd MMMM yyyy, hh:mma",
  SHORT: "dd MMMM yyyy",
  QUERY: "yyyy-MM-dd",
};

export const ISSUE_TYPES = [];
ISSUE_TYPES[0] = "Account and Security";
ISSUE_TYPES[1] = "Subscription";
ISSUE_TYPES[2] = "Other";

export const REASON_TYPES = [];
REASON_TYPES[0] = "Nudity or sexual activity";
REASON_TYPES[1] = "Hat speech or symbols";
REASON_TYPES[2] = "Scam or fraud";
REASON_TYPES[3] = "Violence or dangerous organization";
REASON_TYPES[4] = "Sale of illegal or regulated goods";
REASON_TYPES[5] = "Bullyng or harassment";
REASON_TYPES[6] = "Intellectual propriety violation";
REASON_TYPES[7] = "Suicide or self-injury";
REASON_TYPES[8] = "It's a spam";
REASON_TYPES[9] = "Other";

export const REFUND_TYPES = [];
REFUND_TYPES[0] = "Paypal";
REFUND_TYPES[1] = "Bank Transfer";
REFUND_TYPES[2] = "Other";

export const PAYMENT_TYPES = [];
PAYMENT_TYPES[1] = "Tip";
PAYMENT_TYPES[2] = "Message";
PAYMENT_TYPES[3] = "Subscription";
PAYMENT_TYPES[4] = "Clip";

export const DOCUMENT_TYPES = [];
DOCUMENT_TYPES[1] = "Passport";
DOCUMENT_TYPES[2] = "National ID";

export const VERDICT_TYPES = [];
VERDICT_TYPES[0] = "Pending";
VERDICT_TYPES[1] = "Denied";
VERDICT_TYPES[2] = "Confirmed";

export const USER_TYPE = [];
USER_TYPE[0] = "User";
USER_TYPE[1] = "Creator";

export const ADMIN_TYPE = [];
ADMIN_TYPE[0] = "Administrator";
ADMIN_TYPE[1] = "Contributor";

export const STATUS_TYPE = [];
STATUS_TYPE[0] = "Open";
STATUS_TYPE[1] = "Solved";

export const LAST_MONTH = [
  { name: "Last 12 month", id: 1 },
  { name: "Last 6 month", id: 2 },
  { name: "Last 3 month", id: 3 },
  { name: "Year to Date", id: 4 },
];
