import React, { Component } from "react";
import { RoundButton } from "./RoundButton";

export class ModalSolveTicket extends Component {
    constructor(props) {
        super(props);
    
        this.observer = React.createRef();
      }
    
    componentDidMount = () => {
        
    }

    onSolve = () => {
        this.props.onSolve();
    }

    render() {
        return (
            <div className={"modal-card"}>
                <div className={"header-view"}>
                    <span className={"title-view"}>{ this.props.title }</span>
                    { this.props.subtitle && 
                        <span className={"sub-title-view"}>{ this.props.subtitle }</span>
                    }
                </div>
                <div className={"footer-view"}>
                    <div className="footer-right-button">
                        <span onClick={this.props.onClose}>Cancel</span>
                        <RoundButton type="green" onClick={this.onSolve}>Solve Ticket</RoundButton>
                    </div>
                </div>
            </div>
        )
    }
};