import axios from "axios";
import { store } from "../../redux/store/store";
import { userActions } from "../../redux/actions/user.actions";
import { API_ADMIN_BASEURL } from "../config/constants";

export default function CommonLayoutAuth() {
  axios.interceptors.request.use(
    (config) => {
      const token = store.getState().authentication.userData?.data?.token
        ? store.getState().authentication.userData?.data?.token
        : "";
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.status === 203) {
        store.dispatch(userActions.logout());
      }
      if (response === undefined) {
        store.dispatch(userActions.logout());
      }
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["token"] = token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
        localStorage.clear();
        window.location.href = "/";
        // originalRequest._retry = true;
        // isRefreshing = true;
        // return axios
        //   .post(API_ADMIN_BASEURL + "/auth/refresh", {
        //     refreshToken:
        //       store.getState().authentication.userData.data.refreshToken,
        //     token: store.getState().authentication.userData.data.token,
        //   })
        //   .then((res) => {
        //     if (res.status === 200) {
        //       store.dispatch(userActions.editToken(res.data.data));
        //       processQueue(null, res.data.data.token);
        //       return axios(originalRequest);
        //     } else if (res.status === 500) {
        //       localStorage.clear();
        //       window.location.href = "/";
        //     } else if (res.status === 203) {
        //       window.location.href = "/";
        //       localStorage.clear();
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("ERROR REFRESH:", err);
        //     localStorage.clear();
        //     window.location.href = "/";
        //   })
        //   .finally(() => {
        //     isRefreshing = false;
        //   });
      } else if (error.response.status === 203) {
        store.dispatch(userActions.logout());
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
}
