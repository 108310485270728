import axios from "axios";
import BaseService from "./BaseService";
import { headers } from "../config/constants";

export default class TicketService extends BaseService {
    NAME = "tickets";

    constructor() {
        super();
        this.setPath(this.NAME);
    }

    solveTicket(id) {
        return axios
            .patch(this.mapUrl(id + "/solve-ticket"), {status: 1}, { headers: headers })
            .then((response) => response)
            .catch((error) => {
                throw error.response.data;
            });
    }

    getMessages(id) {
        return axios
            .get(this.mapUrl(id + "/ticket-messages"), { headers: headers })
            .then((response) => response)
            .catch((error) => {
                throw error.response.data;
            });
    }

}
