import { AWS_URL } from "../config/constants";

const PictureHelper = {
  profile_picture: function (user) {
    if (
      user !== null &&
      user.profilePicture !== null &&
      user.profilePicture !== undefined
    )
      return `${AWS_URL}users/${user._id}/profile_pic/${user.profilePicture}`;
    return undefined;
  },
};

export default PictureHelper;
