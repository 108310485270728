import { Types } from "../../components/config/actionTypes";
import { store } from "../store/store";

function login(userAllData) {
  return (dispatch) => {
    let userData = userAllData;
    userData.token = userAllData.token;

    dispatch({
      type: Types.LOGIN,
      payload: { userData: userData, token: userData.token },
    });
  };
}

function edit(userAllData) {
  return (dispatch) => {
    // store.getState()

    let redCircleUser = store.getState().authentication;
    let userData = redCircleUser.userData;

    if (userAllData.token) {
      redCircleUser.token = userAllData.token;
    }
    userData.description = userAllData.description;
    userData.name = userAllData.name;
    userData.userType = userAllData.userType;
    userData.email = userAllData.email;
    userData.profilePicture = userAllData.profilePicture;
    userData.coverImage = userAllData.coverImage;
    userData.userName = userAllData.userName;
    userData.website = userAllData.website;
    userData.instagram = userAllData.instagram;
    userData.twitter = userAllData.twitter;
    userData.snapChat = userAllData.snapChat;
    userData.telegram = userAllData.telegram;
    userData.reddit = userAllData.reddit;
    userData.countryCode = userAllData.countryCode;
    userData.phoneNumber = userAllData.phoneNumber;
    userData.isPhoneVerified = userAllData.isPhoneVerified;
    userData.webLanguageCode = userAllData.webLanguageCode;

    dispatch({
      type: Types.EDIT,
      payload: { userData: userData, token: userData.token },
    });
  };
}

function editToken(tokens) {
  return (dispatch) => {
    let redCircleUser = store.getState().authentication;
    let userData = redCircleUser.userData;

    userData.token = tokens.token;

    dispatch({
      type: Types.EDIT_TOKEN,
      payload: { userData: userData, token: userData.token },
    });
  };
}

function updateSubscribedUserCount(updatedSubscribedUserCount) {
  return (dispatch) => {
    let redCircleUser = store.getState().authentication;
    let userData = redCircleUser.userData;

    userData.subscribedUserCount = updatedSubscribedUserCount;

    dispatch({
      type: Types.UPDATE_SUBSCRIBED_USER_COUNT,
      payload: { userData: userData, token: userData.token },
    });
  };
}

function addStripeCustomerId(stripeCustomerId) {
  return (dispatch) => {
    let redCircleUser = store.getState().authentication;
    let userData = redCircleUser.userData;

    userData.stripeCustomerId = stripeCustomerId;

    dispatch({
      type: Types.ADD_STRIPE_CUSTOMER_ID,
      payload: { userData: userData, token: userData.token },
    });
  };
}

function logout() {
  return (dispatch) => {
    // localStorage.removeItem('redCircleUser');
    // localStorage.removeItem('callNotificationApi');
    // localStorage.removeItem('theme');
    dispatch({
      type: Types.LOGOUT,
      payload: { userData: null, token: null },
    });
    // window.location.reload();
  };
}

export const userActions = {
  login,
  edit,
  editToken,
  updateSubscribedUserCount,
  addStripeCustomerId,
  logout,
};
