import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Main from "./components/container/Main";
import CommonLayoutAuth from "./components/helper/Interceptor";

import "react-toastify/dist/ReactToastify.css";

const BASENAME = process.env.REACT_APP_BASENAME;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    CommonLayoutAuth();

    return (
      <div>
        <BrowserRouter basename={BASENAME}>
          <Main />
          <ToastContainer autoClose={3000} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
