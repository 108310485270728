import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../redux/store/store";
import {
  BiLogOut,
  BiAnalyse,
  BiUserPlus,
  BiCreditCard,
  BiWallet,
  BiChevronDownCircle,
  BiHelpCircle,
  BiShareAlt,
  BiCoin,
} from "react-icons/bi";
import { FiShield, FiUsers } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { CgFileDocument } from "react-icons/cg";
import { toast } from "react-toastify";
import api from "../components/helper/Api.js";
import UserService from "./services/UserService";
import NotificationService from "./services/NotificationService";
import PictureService from "../../src/components/services/PictureService";
import { AWS_URL } from "./config/constants.js";

class Header extends Component {
  userService = null;
  pictureService = null;
  notificationService = null;

  constructor(props) {
    super(props);

    this.state = {
      kyc: false,
      transaction: false,
      withdrawal: false,
      check: false,
      ticket: false,
      isOpen: false,
      isActive: false,
      isNoti: false,
      loading: false,
      sessionId: null,
      subscribedCreatorList: [],
      searchInput: "",
      notifications: {},
      newMessageNotification: false,
      permissions: localStorage.getItem("redcircle")
        ? JSON.parse(localStorage.getItem("redcircle")).authentication.userData
            ?.data?.permissions
        : [],
      userType: localStorage.getItem("redcircle")
        ? JSON.parse(localStorage.getItem("redcircle")).authentication.userData
            ?.data?.userType
        : "",
      id: localStorage.getItem("redcircle")
        ? JSON.parse(localStorage.getItem("redcircle")).authentication.userData
            ?.data?._id
        : "",
      imageProfile: localStorage.getItem("redcircle")
        ? JSON.parse(localStorage.getItem("redcircle")).authentication.userData
            ?.data?.imageProfile
        : null,
    };

    this.toggleSideBar = () => this.setState({ isOpen: !this.state.isOpen });
    this.handleCloseSideBar = () => this.setState({ isOpen: false });
    this.toggleActive = () => this.setState({ isActive: !this.state.isActive });
    this.toggleisNoti = () => this.setState({ isNoti: !this.state.isNoti });

    this.userService = new UserService();
    this.pictureService = new PictureService();
    this.notificationService = new NotificationService();
  }

  componentDidMount = () => {
    this.getUserDetails();
    this.getPermission();
    this.getNotifications();
  };

  getNotifications = () => {
    this.notificationService.all().then((res) => {
      this.setState({ notifications: res.data });
    });
  };

  getProfilePicture = (picture) => {
    if (picture !== null && picture !== undefined) {
      this.pictureService.get(picture).then((res) => {
        this.setState({ imageProfile: res.data.link });
        localStorage.setItem("profile-picture", res.data.link);
      });
    }
  };

  getPermission = () => {
    var userPermisson = {};
    this.userService.get(this.state.id).then(async (res) => {
      if (res.status === 200) {
        localStorage.setItem("userData", JSON.stringify(res.data));
        if (res.data.userType === "Collaborator") {
          res.data.permissions.forEach((element) => {
            userPermisson[element.name] = true;
            this.setState({ [element.name]: true });
          });
          userPermisson.userType = "Collaborator";
          localStorage.setItem("permissions", JSON.stringify(userPermisson));
        } else {
          userPermisson.userType = "Administrator";
          this.setState({ userType: "Administrator" });
          localStorage.setItem("permissions", JSON.stringify(userPermisson));
        }
        this.getProfilePicture(res.data.imageProfile);
      }
    });
  };

  componentDidUpdate = (prevState) => {};

  getUserDetails = () => {
    let redCircleUser = store.getState().authentication;
    let userData = redCircleUser.userData;

    this.setState({
      userData,
    });
  };

  handleTextChangeSearch = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  handleKeyChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      //this.handleSearchSubmit();
    }
  };

  handleLogout = (event) => {
    event.preventDefault();

    let params = {
      _id: this.state.userData._id,
      // sid: this.state.userData.sessionDetails.sid,
    };

    api
      .logout({ ...params })
      .then((res) => {
        localStorage.clear();
        window.location.replace("");
        if (res.status === 200) {
          this.props.logout();
          this.props.removeIpInfo();
          toast.success(res.message);
          window.location.replace("");
        }
      })
      .catch((err) => {
        localStorage.clear();
        window.location.replace("");
        toast.error(err?.response?.data?.message);
        this.setState({ loading: false });
      });
  };

  render() {
    const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
    const buttonClass = `${this.state.isOpen ? " " : ""}`;
    const searchClass = `${this.state.isActive ? " show" : ""}`;
    const notiClass = `${this.state.isNoti ? " show" : ""}`;
    // const closeClass = `${this.state.isOpen ? "active" : ""}`;

    let { isOpen, searchInput } = this.state;

    const width = window.innerWidth;
    var isMobile = width <= 512;
    var isTab = width <= 1024;

    return (
      <>
        <div
          className={`nav-header bg-white border-0 ${
            width > 992 && "nodisplay"
          }`}
          style={{ height: "64px" }}
          ref={this.myRef}
        >
          <div className="nav-top">
            <Link to="/" style={{ justifyContent: "center", gap: "7.24px" }}>
              {/* <i
                className={`fa-regular fa-circle text-light-red ${
                  isMobile ? ' display1-size' : ' font-lg'
                }`}
              />
              <span className="d-inline-block fredoka-font ls-3 fw-500  font-lg logo-text">
                RedCircle
              </span> */}
              <img
                alt=""
                className="logo-red-circle"
                src={`${AWS_URL}logo/logoRedCircle.png`}
              ></img>
              <img
                className="logo-red-circle"
                alt=""
                src={`${AWS_URL}logo/RedCircleBlack.png`}
              ></img>
              <span className="badge rounded-pill red-pill">Admin</span>
            </Link>

            <div className="d-flex align-items-center">
              {!isMobile && !isTab && (
                <span
                  onClick={this.toggleActive}
                  className="me-2 menu-search-icon mob-menu"
                >
                  <i
                    className={`feather-search text-grey-900 font-sm ${
                      isMobile ? " btn-small" : "btn-round-md"
                    } bg-greylight`}
                  ></i>
                </span>
              )}

              <button
                onClick={this.toggleSideBar}
                className={`nav-menu me-0 ms-2 ${buttonClass}`}
              ></button>
            </div>
          </div>

          {!isMobile && !isTab && (
            <form className="float-left d-flex header-search ms-3">
              <div className="form-group mb-0 icon-input">
                <i className="feather-search  text-grey-400 searchIcon"></i>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={this.handleTextChangeSearch}
                  onKeyUp={
                    searchInput?.length > 2 ? this.handleKeyChange : undefined
                  }
                  className="bg-gray search-input   fw-500 w300  pt-2 pb-2  pe-3"
                  style={{
                    border: "1px solid #EEE ",
                    borderRadius: "40px",
                    height: "38px",
                    fontSize: "14px",
                  }}
                />
              </div>
              {searchInput?.length > 2 ? (
                <div
                  onClick={this.handleSearchSubmit}
                  className="input-search-icon pointer"
                >
                  <i className="feather-arrow-right-circle font-lg text-grey-400 ms-auto"></i>
                </div>
              ) : null}
            </form>
          )}
          {/* <NavLink activeClassName="active" to="/notifications" className="p-2 text-center menu-icon center-menu-icon"><i className="feather-bell font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
          <span
            className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
            id="dropdownMenu3"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={this.toggleisNoti}
          ></span>
          {!isMobile && !isTab}

          {isOpen && (
            <div
              style={{
                position: "absolute",
                top: 51,
                left: 0,
                backgroundColor: "#29314585",
                zIndex: "200",
                width: "100%",
                height: "100vh",
              }}
              onClick={this.handleCloseSideBar}
            />
          )}

          <div className={`app-header-search ${searchClass}`}>
            <form className="search-form">
              <div className="form-group searchbox mb-0 border-0 p-1">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={this.handleTextChangeSearch}
                  onKeyDown={
                    searchInput?.length > 2 ? this.handleKeyChange : undefined
                  }
                  className="form-control border-0"
                />
                <i className="input-icon">
                  <ion-icon
                    name="search-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="search outline"
                  ></ion-icon>
                </i>
                <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                  <i
                    className="ti-close font-xs"
                    onClick={this.toggleActive}
                  ></i>
                </span>
              </div>
            </form>
          </div>
        </div>

        <nav className={`navigation bg-navigation scroll-bar ${navClass}`}>
          <div className="container">
            <div className="nav-content">
              <div className="nav-wrap">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to="/" className={"header-logo"}>
                    <img
                      alt=""
                      className="logo-red-circle"
                      src={`${AWS_URL}logo/logoRedCircle.png`}
                    ></img>
                    <img
                      className="logo-red-circle"
                      alt=""
                      src={`${AWS_URL}logo/RedCircleBlack.png`}
                    ></img>
                    <span className="badge rounded-pill red-pill">Admin</span>
                  </Link>
                  <NavLink
                    to="/profile"
                    style={{ paddingTop: "40px" }}
                    onClick={this.handleCloseSideBar}
                  >
                    {this?.props?.loggedUserInfo?.userData?.profilePicture ? (
                      <figure
                        className={`avatar  user-profile-figure-menubar position-relative`}
                        style={{
                          background: `url(${this.state.imageProfile}) 0% 0% / cover no-repeat`,
                        }}
                      >
                        {/* <span className="active-status-button bg-green position-absolute"></span> */}
                      </figure>
                    ) : (
                      <figure
                        className={`avatar  user-profile-figure-menubar position-relative`}
                        style={{
                          background: `url(${this.state.imageProfile}) 0% 0% / cover no-repeat`,
                        }}
                      >
                        {/* <span className="active-status-button bg-green position-absolute"></span> */}
                      </figure>
                    )}
                  </NavLink>
                  <NavLink
                    to="/profile"
                    className="profile-name-container"
                    onClick={this.handleCloseSideBar}
                  >
                    <span className="text-444444 fw-700 p-0 pointer lh-1 fullname-profile font-xsss">
                      {this?.state?.userData?.name}
                    </span>
                    <span className="open-font text-user-name username-profile font-xssss">
                      {this.state.userType}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className="navbar-container">
                <ul>
                  <li className="navbar-item">
                    <NavLink
                      to="/home"
                      activeClassName="nav-tab-active"
                      onClick={this.handleCloseSideBar}
                      className={`nav-content-btn open-font`}
                    >
                      <RxDashboard size={20} style={{ marginRight: "8px" }} />
                      <span>Dashboard</span>
                    </NavLink>
                  </li>
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/logs"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiAnalyse size={20} style={{ marginRight: "8px" }} />
                        <span>Logs</span>
                      </NavLink>
                    </li>
                  )}
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/reviews"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <CgFileDocument
                          size={20}
                          style={{ marginRight: "8px" }}
                        />
                        <span>
                          Reports Review
                          {this.state.notifications.pendingReports && (
                            <span className="activity-dot"></span>
                          )}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(this.state.userType === "Administrator" ||
                    this.state.kyc === true) && (
                    <li className="navbar-item">
                      <NavLink
                        to="/kycs"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiUserPlus size={20} style={{ marginRight: "8px" }} />
                        <span>
                          KYC Management
                          {this.state.notifications.pendingKycs && (
                            <span className="activity-dot"></span>
                          )}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(this.state.userType === "Administrator" ||
                    this.state.transaction === true) && (
                    <li className="navbar-item">
                      <NavLink
                        to="/payments"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiCreditCard
                          size={20}
                          style={{ marginRight: "8px" }}
                        />
                        <span>Payment Records</span>
                      </NavLink>
                    </li>
                  )}
                  {(this.state.userType === "Administrator" ||
                    this.state.withdrawal === true) && (
                    <li className="navbar-item">
                      <NavLink
                        to="/withdrawals"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiWallet size={20} style={{ marginRight: "8px" }} />
                        <span>
                          Withdrawal Management
                          {this.state.notifications.pendingWithdrawals && (
                            <span className="activity-dot"></span>
                          )}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(this.state.userType === "Administrator" ||
                    this.state.check === true) && (
                    <li className="navbar-item">
                      <NavLink
                        to="/verifications"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiChevronDownCircle
                          size={20}
                          style={{ marginRight: "8px" }}
                        />
                        <span>
                          Verification Management
                          {this.state.notifications.pendingChecks && (
                            <span className="activity-dot"></span>
                          )}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/users"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <FiUsers size={20} style={{ marginRight: "8px" }} />
                        <span>User Management</span>
                      </NavLink>
                    </li>
                  )}
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/admins"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <FiShield size={20} style={{ marginRight: "8px" }} />
                        <span>Admin Management</span>
                      </NavLink>
                    </li>
                  )}
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/vat"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <FiShield size={20} style={{ marginRight: "8px" }} />
                        <span>VAT/Tax</span>
                      </NavLink>
                    </li>
                  )}
                  {(this.state.userType === "Administrator" ||
                    this.state.ticket === true) && (
                    <li className="navbar-item">
                      <NavLink
                        to="/supports"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiHelpCircle
                          size={20}
                          style={{ marginRight: "8px" }}
                        />
                        <span>
                          Support Ticket
                          {this.state.notifications.pendingTickets && (
                            <span className="activity-dot"></span>
                          )}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/referral_users"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiShareAlt size={20} style={{ marginRight: "8px" }} />
                        <span>Referral System</span>
                      </NavLink>
                    </li>
                  )}
                  {this.state.userType === "Administrator" && (
                    <li className="navbar-item">
                      <NavLink
                        to="/referrals"
                        activeClassName="nav-tab-active"
                        onClick={this.handleCloseSideBar}
                        className={`nav-content-btn open-font`}
                      >
                        <BiCoin size={20} style={{ marginRight: "8px" }} />
                        <span>Fee Settings</span>
                      </NavLink>
                    </li>
                  )}
                  <li className="navbar-item">
                    <NavLink
                      to="#"
                      activeClassName="nav-tab"
                      onClick={this.handleLogout}
                      className={`nav-content-btn open-font`}
                    >
                      <BiLogOut size={20} style={{ marginRight: "8px" }} />
                      <span>Logout</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
