import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/index';
import { loadState, saveState } from '../../components/helper/LocalstorageHelper';

const peristedState = loadState();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore( 
    rootReducer,
    peristedState,
    composeEnhancer(applyMiddleware( thunkMiddleware))
);



store.subscribe(() => {
    saveState(store.getState());
});